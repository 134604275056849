import { userRoles } from 'gogo-sphere-shared-redux';
import { withLoadable } from 'gogo-sphere-shared-components';

export default {
	name: 'High Value Customer', // Application name
	path: 'hvc', // Path that is being used in a router
	redirect: 'hvc/batch_submit', // Used in app switcher and app selector to control on which url will application open
	roles: [userRoles.GOGO_SUPER_ADMIN, userRoles.GOGO_ADMIN, userRoles.PARTNER_ADMIN, userRoles.USER], // For which roles app would be open - deprecated soon
	airlines: ['JAL'], // For which airlines app would be open
	showPartnerLogo: true, // If true, airline logo will appear in header
	component: withLoadable(() =>
		import(/*webpackChunkName: "hvc"*/
		/*webpackMode: "lazy"*/
		'gogo-sphere-app-hvc')
	),
	pages: [
		// Defines paged for application
		{
			sidebar: true, // Should page be shown in menu
			name: 'Batch submit', // Name for the application - used in sidebar
			icon: 'glossary', // Icon name from Icons.jsx
			path: 'batch_submit', // Path added to router to main application path
			component: 'BatchSubmitPage', // Component which is called for this router
			roles: [userRoles.GOGO_SUPER_ADMIN, userRoles.GOGO_ADMIN, userRoles.PARTNER_ADMIN, userRoles.USER], // For which roles page would be open - deprecated soon
			permission: {
				// Permissions for page based on role
				domain: 'hvc',
				target: 'batch_request',
				action: 'read',
			},
		},
		{
			sidebar: true,
			name: 'Admin home',
			icon: 'wrench', // Icon name from Icons.jsx
			path: 'home',
			component: 'AdminHome',
			roles: [userRoles.GOGO_SUPER_ADMIN, userRoles.GOGO_ADMIN],
			permission: {
				domain: 'usermanagement',
				target: 'partner',
			},
		},
		{
			name: 'Partner overview',
			path: 'partners/partner/:id',
			component: 'PartnerPage',
			roles: [userRoles.GOGO_SUPER_ADMIN, userRoles.GOGO_ADMIN],
			permission: {
				domain: 'usermanagement',
				target: 'partner',
			},
		},
	],
};
