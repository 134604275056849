import { withLoadable } from 'gogo-sphere-shared-components';

export default {
	name: 'Flex Developer', // Application name
	shortName: 'Flex Developer',
	mobileName: 'Flex Dev',
	path: 'depo', // Path that is being used in a router
	redirect: 'depo/apis', // Used in app switcher and app selector to control on which url will application open
	roles: [], // For which roles app would be open - deprecated soon
	airlines: '*', // For which airlines app would be open
	noAppHeader: true, // Should application have header with default breadcrumbs
	tabNavigationOverride: false, // Tab navigation instead of sidebar,
	exceptionalRestriction: false, // in case this is true, it wont appear on production / stage
	showPartnerLogo: true, // shows currently selected airline logo
	component: withLoadable(() =>
		import(/*webpackChunkName: "depo"*/
		/*webpackMode: "lazy"*/
		'gogo-sphere-app-depo')
	),
	pages: [
		// Defines paged for application
		{
			sidebar: true, // Should page be shown in menu
			name: 'APIs', // Name for the application - used in sidebar
			path: 'apis',
			icon: 'aircraft', // Icon name from Icons.jsx
			component: 'APIs', // Component which is called for this router
			roles: [], // For which roles page would be open - deprecated soon
			permission: {
				// Permissions for page based on role
				domain: 'devportal',
				target: 'manage',
				action: 'read',
			},
		},
		{
			sidebar: true, // Should page be shown in menu
			name: 'Admin', // Name for the application - used in sidebar
			path: 'admin',
			icon: 'wrench', // Icon name from Icons.jsx
			component: 'ManageAPIs', // Component which is called for this router
			roles: [], // For which roles page would be open - deprecated soon
			permission: {
				// Permissions for page based on role
				domain: 'devportal',
				target: 'manage',
				action: '*',
			},
			pages: [
				{
					sidebar: false, // Should page be shown in menu
					name: 'Manage Access', // Name for the application - used in sidebar
					path: 'manageaccess',
					component: 'APIsAccess', // Component which is called for this router
					roles: [], // For which roles page would be open - deprecated soon
				},
			],
		},
	],
};
