import React from 'react';
import { connect, Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';

import { store, authActionCreators, messages } from 'gogo-sphere-shared-redux';

import Routes from './Routes';

import ErrorBoundary from './Util/ErrorBoundary';

const mapStateToProps = state => ({
	loginStatus: state.loginStatus,
	userPrefs: state.userPrefs,
	tableauApps: state.tableauApps.data._embedded ? state.tableauApps.data._embedded.applications : [],
	tableauInternalApps: state.tableauInternalApps.data ? state.tableauInternalApps.data : [],
	BIATableauAppId: state.BIATableauAppId,
});

const mapDispatchToProps = dispatch => ({
	setKickTimer: () => dispatch(authActionCreators.setKickTimer()),
});

const ConnectedRoutes = connect(
	mapStateToProps,
	mapDispatchToProps
)(Routes);

const beforeSend = report => {
	let user = localStorage.getItem('current_user');

	if (user) {
		user = JSON.parse(user);
		// eslint-disable-next-line no-param-reassign
		report.user = {
			id: user.id,
			email: user.email,
			roles: user.roles,
			permissions: user.permissions,
		};
	}

	const partner =
		localStorage.getItem('partnerCode') ||
		'Partner not set (possible reasons: user not logged in or data could not be saved to local storage)';

	report.updateMetaData('browserLanguage', navigator.language);
	report.updateMetaData('airline', partner);
};

export default class RoutesWithWrappers extends React.Component {
	render() {
		return (
			<ErrorBoundary beforeSend={beforeSend}>
				<IntlProvider locale="en" messages={messages['en'].messages}>
					<Provider store={store}>
						<ConnectedRoutes {...this.props} />
					</Provider>
				</IntlProvider>
			</ErrorBoundary>
		);
	}
}
