import { withLoadable } from 'gogo-sphere-shared-components';

export default {
	name: 'Maslo', // Application name
	path: 'maslo', // Path that is being used in a router
	redirect: 'maslo/',
	roles: [], // For which roles app would be open - deprecated soon
	airlines: '*', // For which airlines app would be open
	noAppHeader: true,
	showPartnerLogo: false, // If true, airline logo will appear in header
	header: {
		alignHeaderLeft: true,
		showAppIconInHeader: true,
	},
	exceptionalRestriction: () => window.env !== 'dev' && window.env !== 'stage',
	component: withLoadable(() =>
		import(/*webpackChunkName: "maslo"*/
		/*webpackMode: "lazy"*/
		'gogo-sphere-app-maslo')
	),
	permission: {
		domain: 'imp', // the has to be a permission for at least the app
		//maslo should have its own permissions, replace this when they are ready
	},
	pages: [
		{
			sidebar: true,
			name: 'Maslo',
			icon: 'wrench',
			path: '/',
			component: 'Maslo',
			roles: [],
		},
	],
};
