import { userRoles } from 'gogo-sphere-shared-redux';
import { withLoadable } from 'gogo-sphere-shared-components';

export default {
	name: 'Active Aircraft View', // Application name
	path: 'fiw', // Path that is being used in a router
	redirect: 'fiw/worldmap', // Used in app switcher and app selector to control on which url will application open
	roles: [userRoles.GOGO_SUPER_ADMIN, userRoles.GOGO_ADMIN, userRoles.PARTNER_ADMIN], // For which roles app would be open - deprecated soon
	airlines: ['WOR', 'ACA', 'AAL'], // For which airlines app would be open
	exceptionalRestriction: false, // in case this is true, it wont appear on production
	showPartnerLogo: true, // If true, airline logo will appear in header
	noAppHeader: true, // If true, app header wont be shown
	extraPermissions: ['fiw'], // Permissions for application based on user
	component: withLoadable(() =>
		import(/*webpackChunkName: "fiw"*/
		/*webpackMode: "lazy"*/
		'gogo-sphere-app-fiw')
	),
	pages: [
		// Defines paged for application
		{
			sidebar: true, // Should page be shown in menu
			name: '', // Name for the application - used in sidebar
			icon: '', // Icon name from Icons.jsx
			path: 'worldmap', // Path added to router to main application path
			component: 'WorldMap', // Component which is called for this router
			roles: [userRoles.GOGO_SUPER_ADMIN, userRoles.GOGO_ADMIN, userRoles.PARTNER_ADMIN], // For which roles page would be open - deprecated soon
			extraPermission: 'fiw', // Permissions for page based on user
			permission: {
				// Permissions for page based on role
				domain: 'fiw',
				target: null,
				action: 'read',
			},
		},
	],
};
