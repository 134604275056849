import { userRoles } from 'gogo-sphere-shared-redux';
import { withLoadable } from 'gogo-sphere-shared-components';

export default {
	name: 'File Share', // Application name
	path: 'fileshare', // Path that is being used in a router
	mobileName: 'FILESHARE',
	redirect: 'fileshare/', // Used in app switcher and app selector to control on which url will application open
	roles: [userRoles.GOGO_ADMIN, userRoles.GOGO_SUPER_ADMIN, userRoles.PARTNER_ADMIN, userRoles.GOGO_USER], // For which roles app would be open - deprecated soon
	airlines: '*', // For which airlines app would be open
	showPartnerLogo: true, // If true, airline logo will appear in header
	noAppHeader: true, // Should application have header with default breadcrumbs
	extraPermissions: ['fileshare'], // Permissions for application based on user
	permission: {
		// Permissions for application based on role
		domain: 'fileshare',
	},
	component: withLoadable(() => import(/*webpackChunkName: "fs", webpackMode: "lazy"*/ 'gogo-sphere-app-fs')),
	pages: [
		// Defines paged for application
		{
			sidebar: true, // Should page be shown in menu
			name: 'File Share', // Name for the application - used in sidebar
			icon: 'fileshare', // Icon name from Icons.jsx
			path: '/', // Path added to router to main application path
			component: 'FilesShareContainer', // Component which is called for this router
			roles: [userRoles.GOGO_ADMIN, userRoles.GOGO_SUPER_ADMIN, userRoles.PARTNER_ADMIN, userRoles.GOGO_USER], // For which roles page would be open - deprecated soon
			extraPermission: 'fileshare', // Permissions for page based on user
		},
		{
			sidebar: false,
			name: 'File download',
			icon: 'icon2', // Icon name from Icons.jsx
			path: 'download/:fileId',
			component: 'FileDownload',
			roles: [userRoles.GOGO_ADMIN, userRoles.GOGO_SUPER_ADMIN, userRoles.PARTNER_ADMIN, userRoles.GOGO_USER],
			extraPermission: 'fileshare',
		},
	],
};
