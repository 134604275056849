import React from 'react';
import {LinkWithScrollToTop} from 'gogo-sphere-shared-components';

export default class extends React.Component {
    static displayName = 'LinkHasExpired';

    render() {
		return (
			<div className="ErrorPage h-textCenter" data-automation="regionLinkExpired">
				<br />
				<br />
				<br />
				<br />
				<div data-automation="regionExpired" className="h-centerVerticallyAndHorizontally">
					<div>
						<h1 data-automation="textExpired" className="xxl">Expired</h1>
						<p data-automation="textOops" className="ErrorPage__text h-whiteText">Oops! Something went
							wrong</p>
						<p data-automation="textLinkHasExpired" className="ErrorPage__text h-whiteText">
							This link has expired and is no more valid. Return to
							<LinkWithScrollToTop className="ErrorPage__link"
								data-automation="actionGoToHomePage"
								to="/"> Home
								page</LinkWithScrollToTop>
						</p>
					</div>
				</div>
			</div>
		);
	}
}