import React from 'react';
import TableauApp from '../Components/Tableau/TableauApp';
import TableauReport from '../Components/Tableau/TableauReport';

const makeTableauAppsManifests = tableauApps =>
	tableauApps.map(app => {
		const name = app.name.replace(/_/g, '');
		return {
			tableauApp: true,
			appId: app.id,
			name: app.display_name, // Application name
			path: `${name}reporting`, // Path that is being used in a router
			redirect: `${name}reporting/reports/${app.id}`, // Used in app switcher and app selector to control on which url will application open
			icon: app.icon, // Icon for app selector
			menuIcon: app.icon, // Icon for app switcher
			airlines: app.airlines, // For which airlines app would be open
			showPartnerLogo: true,
			roles: [],
			pages: [
				// Defines paged for application
				{
					sidebar: true,
					name: 'Reports',
					icon: 'glossary',
					path: `reports/${app.id}`,
					component: props => <TableauApp {...props} internal={app.internal} />,
					roles: [],
					permission: () => app.reports.length !== 0,
					pages: app.reports.map(report => ({
						name: 'Report',
						path: `report/${report.id}`,
						component: TableauReport,
						permission: () => true,
						roles: [],
					})),
				},
			],
		};
	});

export default makeTableauAppsManifests;
