import React from 'react';
import PropTypes from 'prop-types';
import { SmartButton } from 'gogo-sphere-shared-components';

class SSOLogin extends React.Component {
	static propTypes = {
		disabled: PropTypes.bool,
		isLoggingIn: PropTypes.bool,
		handleSSO: PropTypes.func,
	};

	render() {
		return (
			<div className="SSOLogin__wrapper h-marginT--xxl" data-automation="regionSSOLogin">
				<div className="SSOLogin__separator">
					<div className="SSOLogin__separatorLineLeft" />
					<div className="SSOLogin__separatorOr h-textCenter">OR</div>
					<div className="SSOLogin__separatorLineRight" />
				</div>
				<SmartButton
					type="button"
					name="oktaLoginButton"
					className="SSOLogin__button Button Button--action Button--action--white h-fullWidth h-marginT--xxl"
					dataAutomation="fieldLoginWithSSOLoginButton"
					isInPendingState={this.props.isLoggingIn}
					isDisabled={this.props.disabled}
					onClickCallback={event => {
						event.preventDefault();
						this.props.handleSSO('microsoftonline');
					}}
					text="Sign in as Intelsat employee"
					pendingText="Signing in..."
				/>
			</div>
		);
	}
}

export default SSOLogin;
