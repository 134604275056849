import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect, Provider } from 'react-redux';
import PropTypes from 'prop-types';
import { store, tableauReportsActionCreators } from 'gogo-sphere-shared-redux';
import { ReportsList } from 'gogo-sphere-shared-components';

class _TableauApp extends React.Component {
	static propTypes = {
		getReports: PropTypes.func,
		partnerCode: PropTypes.string,
		tableauApps: PropTypes.array,
		getReportToken: PropTypes.func,
		isLoading: PropTypes.bool,
		BIATableauAppId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		tblReports: PropTypes.array,
		user: PropTypes.object,
		internal: PropTypes.bool,
		tableauInternalApps: PropTypes.array,
	};
	componentDidMount() {
		const appId = this.props.location.pathname.substring(
			this.props.location.pathname.lastIndexOf('/') + 1,
			this.props.location.pathname.length
		);
		if (this.props.internal) {
      return this.props.getReports('internal', appId);
    }
		return this.props.getReports(this.props.partnerCode, appId);
	}

	render() {
		const reports = this.props.tblReports || [];
		const appId = this.props.location.pathname.substring(
			this.props.location.pathname.lastIndexOf('/') + 1,
			this.props.location.pathname.length
		);

		const tblApp = this.props.internal ? this.props.tableauInternalApps.find(app => app.id === appId) : this.props.tableauApps.find(app => app.id === appId);
		return (
			<div data-automation="regionTableauAppMain" className="TableauApp">
				<ReportsList
					partnerCode={this.props.partnerCode}
					app={tblApp}
					BIATableauAppId={this.props.BIATableauAppId}
					getReportToken={this.props.getReportToken}
					isLoading={this.props.isLoading}
					reports={reports}
					user={this.props.user}
				/>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	tableauApps: state.tableauApps.data._embedded ? state.tableauApps.data._embedded.applications : [],
	tableauInternalApps: state.tableauInternalApps.data ? state.tableauInternalApps.data : [],
	tblReports: state.tableauReports.data._embedded ? state.tableauReports.data._embedded.reports : [],
	isLoading: state.tableauReports.listLoading,
	BIATableauAppId: state.BIATableauAppId,
});

const mapDispatchToProps = dispatch => ({
	getReportToken: (partnerCode, reportName, callback) =>
		dispatch(tableauReportsActionCreators.getReportToken(partnerCode, reportName, callback)),
	getReports: (partnerCode, appId) => dispatch(tableauReportsActionCreators.getReports(partnerCode, appId)),
});

const TableauAppWithRouter = withRouter(_TableauApp);
const TableauApp = connect(
	mapStateToProps,
	mapDispatchToProps
)(TableauAppWithRouter);

export default class extends React.Component {
	render() {
		return (
			<Provider store={store}>
				<TableauApp {...this.props} />
			</Provider>
		);
	}
}
