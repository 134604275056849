import { userRoles } from 'gogo-sphere-shared-redux';
import { withLoadable } from 'gogo-sphere-shared-components';

const allCmpRoles = [
	userRoles.CMP_USER,
	userRoles.CMP_AGENT,
	userRoles.CMP_LEAD,
	userRoles.CMP_SUPERVISOR,
	userRoles.CMP_ADMIN,
];

export default {
	name: 'Customer Management Portal', // Application name
	shortName: 'Customer Mgmt Portal', // Application name used for app switcher
	mobileName: 'CMP',
	path: 'cmp', // Path that is being used in a router
	redirect: 'cmp/search', // Used in app switcher and app selector to control on which url will application open
	roles: allCmpRoles, // For which roles app would be open - deprecated soon
	airlines: '*', // For which airlines app would be open
	tabNavigationOverride: true, // Tab navigation instead of sidebar
	exceptionalRestriction: false, // in case this is true, it wont appear on production
	component: withLoadable(() =>
		import(/*webpackChunkName: "cmp"*/
		/*webpackMode: "lazy"*/
		'gogo-sphere-app-cmp')
	),
	header: {
		alignHeaderLeft: true,
		showAppIconInHeader: false,
	},
	pages: [
		// Defines paged for application
		{
			sidebar: true, // Should page be shown in menu
			name: 'Main Search', // Name for the application - used in sidebar
			icon: 'glossary', // Icon name from Icons.jsx
			path: 'search', // Path added to router to main application path
			component: 'Search', // Component which is called for this router
			roles: allCmpRoles, // For which roles page would be open - deprecated soon
			permission: {
				// Permissions for page based on role
				domain: 'cmp',
			},
		},
		{
			sidebar: true,
			name: 'Executive Account',
			icon: 'wrench', // Icon name from Icons.jsx
			path: 'executive',
			component: 'ExecutiveAccounts',
			roles: [userRoles.CMP_ADMIN],
			permission: {
				domain: 'cmp',
				action: 'admin',
			},
		},
		{
			sidebar: true,
			name: 'Refunds',
			icon: 'wrench', // Icon name from Icons.jsx
			path: 'refunds',
			component: 'Refunds',
			roles: [userRoles.CMP_LEAD, userRoles.CMP_SUPERVISOR, userRoles.CMP_ADMIN],
			permission: {
				domain: 'cmp',
				action: 'lead',
			},
			pages: [
				{
					sidebar: false,
					name: 'Refund Report',
					icon: '', // Icon name from Icons.jsx
					path: 'report',
					component: 'RefundReport',
					roles: [userRoles.CMP_SUPERVISOR, userRoles.CMP_ADMIN],
					permission: {
						domain: 'cmp',
						action: 'supervisor',
					},
				},
				{
					sidebar: false,
					name: 'Refund Task List',
					icon: '', // Icon name from Icons.jsx
					path: 'tasklist',
					component: 'RefundTasklist',
					roles: [userRoles.CMP_LEAD, userRoles.CMP_SUPERVISOR, userRoles.CMP_ADMIN],
				},
			],
		},
		{
			sidebar: true,
			name: 'Admin Access',
			icon: 'wrench', // Icon name from Icons.jsx
			path: 'admin-access',
			component: 'AdminAccess',
			roles: [userRoles.CMP_ADMIN],
			permission: {
				domain: 'cmp',
				action: 'admin',
			},
		},
	],
};
