import { userRoles } from 'gogo-sphere-shared-redux';

export default {
	name: 'GSR Tracker', // Application name
	shortName: 'GSR Tracker', // Application name used for app switcher
	class: 'esr',
	header: {},
	redirect: 'http://app.esrtracker.prod.ca.intelsat.com/', // soon to be changed to http://app.esrtracker.ca.intelsat.com/
	roles: Object.values(userRoles), // For which roles app would be open - deprecated soon
	airlines: '*', // For which airlines app would be open
	pages: [],
	externalApp: true,
	exceptionalRestriction: user =>
		!user.partner_codes ||
		(user.partner_codes &&
			(user.partner_codes.length == 0 ||
				(user.partner_codes.length == 1 && user.partner_codes[0] == 'STAR') ||
				(user.partner_codes.length == 1 && user.partner_codes[0] == 'TMOB'))),
};
