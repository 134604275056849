import React from 'react';
import { connect, Provider } from 'react-redux';
import { store, authActionCreators } from 'gogo-sphere-shared-redux';
import PropTypes from 'prop-types';

class _Logout extends React.Component {
	static propTypes = {
		logout: PropTypes.func,
	};

	componentDidMount() {
		this.props.history.push('/login');
		this.props.logout();
	}

	render() {
		return null;
	}
}

const mapDispatchToProps = dispatch => ({
	logout: (email, password) => dispatch(authActionCreators.logout(email, password)),
});

const Logout = connect(null, mapDispatchToProps)(_Logout);

export default class extends React.Component {
	render() {
		return (
			<Provider store={store}>
				<Logout {...this.props} />
			</Provider>
		);
	}
}
