import { withLoadable } from 'gogo-sphere-shared-components';

export default {
	name: 'Product Data Manager', // Application name
	path: 'pdm', // Path that is being used in a router
	redirect: 'pdm/datamanager', // Used in app switcher and app selector to control on which url will application open
	roles: [], // For which roles app would be open - deprecated soon
	airlines: '*', // For which airlines app would be open
	noAppHeader: true, // Should application have header with default breadcrumbs
	tabNavigationOverride: true, // Tab navigation instead of sidebar,
	exceptionalRestriction: () => window.env !== 'dev' && window.env !== 'stage', // in case this is true, it wont appear on production / stage
	showPartnerLogo: true, // shows currently selected airline logo
	permission: {
		domain: 'pdm',
	},
	component: withLoadable(() =>
		import(/*webpackChunkName: "pdm"*/
		/*webpackMode: "lazy"*/
		'gogo-sphere-app-pdm')
	),
	pages: [
		// Defines paged for application
		{
			sidebar: true, // Should page be shown in menu
			name: 'Data Manager', // Name for the application - used in sidebar
			path: 'datamanager',
			component: 'ProductManagerRoot', // Component which is called for this router
			roles: [], // For which roles page would be open - deprecated soon
		},
	],
};
