export const checkTopsViewPermissions = permissions =>
	permissions.some(
		permission =>
			permission.domain === 'tops' &&
			permission.target === 'package' &&
			(permission.action === 'read' || permission.action === 'owner_read')
	);

export const checkTopsUpdatePermissions = permissions =>
	permissions.some(
		permission =>
			permission.domain === 'tops' &&
			permission.target === 'package' &&
			(permission.action === 'update' || permission.action === 'owner_update')
	);

export const checkTopsConfigViewPagePermissions = permissions =>
	permissions.some(
		permission =>
			permission.domain === 'tops' && permission.target === 'golden_config_status' && permission.action === 'read'
	);
