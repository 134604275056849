import { userRoles } from 'gogo-sphere-shared-redux';
import { withLoadable } from 'gogo-sphere-shared-components';

export default {
	name: 'Administration', // Application name
	path: 'admin', // Path that is being used in a router
	mobileName: 'ADMIN',
	redirect: 'admin/manageusers', // Used in app switcher and app selector to control on which url will application open
	roles: [userRoles.GOGO_SUPER_ADMIN, userRoles.GOGO_ADMIN, userRoles.PARTNER_ADMIN], // For which roles app would be open - deprecated soon
	airlines: '*', // For which airlines app would be open
	component: withLoadable(() =>
		import(/*webpackChunkName: "admin"*/
		/*webpackMode: "lazy"*/
		'gogo-sphere-app-admin')
	),
	pages: [
		// Defines pages for application
		{
			sidebar: true, // Should page be shown in menu
			name: 'Manage users', // Name for the application - used in sidebar
			icon: 'wrench', // Icon name from Icons.jsx
			path: 'manageusers', // Path added to router to main application path
			component: 'AdminTools', // Component which is called for this router
			roles: [userRoles.GOGO_SUPER_ADMIN, userRoles.GOGO_ADMIN, userRoles.PARTNER_ADMIN], // For which roles page would be open - deprecated soon
			permission: {
				// Permissions for page based on role
				domain: 'usermanagement',
				target: 'account',
			},
		},
		{
			sidebar: true,
			name: 'Manage partners',
			icon: 'aircraft', // Icon name from Icons.jsx
			path: 'partners',
			component: 'PartnerListAdmin',
			roles: [userRoles.GOGO_SUPER_ADMIN, userRoles.GOGO_ADMIN],
			permission: {
				domain: 'usermanagement',
				target: 'partner',
				action: '*',
			},
			pages: [
				{
					name: 'Partners details',
					path: 'partner/:id',
					component: 'PartnerDetailsAdmin',
					roles: [userRoles.GOGO_SUPER_ADMIN, userRoles.GOGO_ADMIN],
					permission: {
						domain: 'usermanagement',
						target: 'partner',
						action: '*',
					},
					pages: [
						{
							name: 'Manage roles',
							path: 'manageroles',
							component: 'ManageRoles',
							roles: [],
						},
					],
				},
			],
		},
		{
			name: 'Manage roles',
			path: 'roles',
			component: 'Roles',
			roles: [userRoles.GOGO_SUPER_ADMIN, userRoles.GOGO_ADMIN],
			permission: {
				domain: 'usermanagement',
				target: 'role',
			},
		},
		{
			sidebar: true,
			icon: 'glossary', // Icon name from Icons.jsx
			name: 'Manage Tableau apps',
			path: 'tableauapps',
			component: 'ManageTableauApps',
			roles: [userRoles.GOGO_SUPER_ADMIN],
			permission: {
				domain: 'usermanagement', // check?????
				target: 'manage_tableau',
				action: 'read',
			},
			pages: [
				{
					name: 'Manage reports',
					path: 'managereports',
					component: 'ManageReports',
					roles: [userRoles.GOGO_SUPER_ADMIN],
				},
			],
		},
		{
			sidebar: true,
			icon: 'glossary', // Icon name from Icons.jsx
			name: 'Manage IDP',
			path: 'manageidp',
			component: 'ManageIDP',
			roles: [userRoles.GOGO_SUPER_ADMIN],
			permission: {
				domain: 'usermanagement',
				target: 'manage_idp',
				action: '*',
			},
			pages: [
				{
					name: 'Details IDP',
					path: 'providerdetails',
					component: 'DetailsPageIDP',
					roles: [userRoles.GOGO_SUPER_ADMIN],
				},
			],
		},
	],
};
