import React from 'react';
import { connect, Provider } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { LoadingIndicator, LinkWithScrollToTop, NotificationSystem } from 'gogo-sphere-shared-components';
import { store, authActionCreators, getUserPrefs } from 'gogo-sphere-shared-redux';

class _SSOLanding extends React.Component {
	static propTypes = {
		clearLoginError: PropTypes.func,
		sendRequestToGetCurrentUserDetails: PropTypes.func,
		sendSSOAuthCode: PropTypes.func,
		getUserPrefs: PropTypes.func,
		loginStatus: PropTypes.string,
		notifications: PropTypes.array,
		loginErrorMessage: PropTypes.object,
	};

	componentDidMount() {
		this.props.clearLoginError();

		window.adobeDTMData = {
			airline: null,
			roles: null,
		};

		this.loginWithSSO();
	}

	componentDidUpdate() {
		if (this.props.loginStatus === 'LOGGED_IN') {
			this.props.getUserPrefs();
			this.props.history.replace('/');
		}
	}

	loginWithSSO = () => {
		const start = this.props.location.search.indexOf('=');
		const end = this.props.location.search.length;
		const authCode = this.props.location.search.substring(start + 1, end);

		if (authCode) {
			this.props.sendSSOAuthCode(authCode, () => {
				this.props.sendRequestToGetCurrentUserDetails();
			});
		}
	};

	render() {
		return (
			<div className="SSOLanding h-textCenter" data-automation="regionSSOLanding">
				<NotificationSystem notifications={this.props.notifications} />

				{!this.props.loginErrorMessage ? (
					<h2 className="SSOLanding__text h-textCenter h-marginB--xxl" data-automation="textLoggingIn">
						<LoadingIndicator text="" />
						Logging in...
					</h2>
				) : null}
				<div data-automation="textAccessDenied" className="ErrorPage__text h-whiteText">
					<p>
						{this.props.loginErrorMessage
							? 'Oops! Something went wrong'
							: 'In case this is taking too long'}
					</p>
					<span>Return to</span>
					<LinkWithScrollToTop className="ErrorPage__link" data-automation="actionGoToHomePage" to="/">
						&nbsp;Home page
					</LinkWithScrollToTop>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	loginStatus: state.loginStatus,
	loginErrorMessage: state.loginErrorMessage,
	notifications: state.notifications,
});

const mapDispatchToProps = dispatch => ({
	sendSSOAuthCode: (authCode, callback) => dispatch(authActionCreators.sendSSOAuthCode(authCode, callback)),
	sendRequestToGetCurrentUserDetails: () => dispatch(authActionCreators.sendRequestToGetCurrentUserDetails()),
	getUserPrefs: () => dispatch(getUserPrefs()),
	clearLoginError: () => dispatch(authActionCreators.clearLoginError()),
});

export const SSOLandingWithRouter = withRouter(_SSOLanding);
const SSOLanding = connect(mapStateToProps, mapDispatchToProps)(SSOLandingWithRouter);

export default class extends React.Component {
	render() {
		return (
			<Provider store={store}>
				<SSOLanding {...this.props} />
			</Provider>
		);
	}
}
