import esr from './externalApps/esr';
import crew from './externalApps/crewOperations';

import admin from './src/manifests/admin';
import bia from './src/manifests/bia';
import cmp from './src/manifests/cmp';
import imp from './src/manifests/imp';
import depo from './src/manifests/depo';
import pcd from './src/manifests/pcd';
import pdm from './src/manifests/pdm';
import lab from './src/manifests/lab';
import fiw from './src/manifests/fiw';
import hvc from './src/manifests/hvc';
import fig from './src/manifests/fig';
import fs from './src/manifests/fs';
import tops from './src/manifests/tops';
import maslo from './src/manifests/maslo';

export default {
	admin,
	hvc,
	bia,
	lab,
	fs,
	fiw,
	fig,
	cmp,
	pdm,
	pcd,
	imp,
	depo,
	tops,
	maslo,
	esr,
	crew,
};
