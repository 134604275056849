//babel-polyfill isn't here because it's loaded in webpack
//if you think we need it and want to add it here, don't, it's already taken care of by webpack
import React from 'react';
import ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';

import { changeLocale, store } from 'gogo-sphere-shared-redux';

// Font awesome
import 'font-awesome/css/font-awesome.min.css';

import 'classlist-polyfill';
import 'nodelist-foreach-polyfill';
import 'url-search-params-polyfill';
import './Polyfills';
import '@babel/runtime-corejs2/core-js/get-iterator';// eslint-disable-line
import '@babel/runtime-corejs2/core-js/is-iterable';// eslint-disable-line

// Fonts
import '../assets/css/spinner.css';
import '../assets/css/fonts-css/fonts.css';

import Routes from './RoutesWithWrappers';
import './Notifications';

// Main loader file for scss (this need to be import not require)
import '../assets/main.scss';

/*function renderGGS(App) {
	ReactDOM.render(
		<ErrorBoundary beforeSend={beforeSend}>
			<AppContainer warnings={false}>
				<IntlProvider locale="en" messages={messages['en'].messages}>
					<App />
				</IntlProvider>
			</AppContainer>
		</ErrorBoundary>,
		document.getElementById('app')
	);
}*/

function render(Component) {
	ReactDOM.render(
		<AppContainer>
			<Component />
		</AppContainer>,
		document.getElementById('app')
	);
}

render(Routes);

store.dispatch(changeLocale(navigator.language || navigator.browserLanguage));

if (module.hot) {
	module.hot.accept('./RoutesWithWrappers', () => {
		//eslint-disable-next-line
		render(require('./RoutesWithWrappers'));
	});
}
