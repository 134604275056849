import React from 'react';
import { connect, Provider } from 'react-redux';
import { store, passwordActionCreators } from 'gogo-sphere-shared-redux';
import PropTypes from 'prop-types';
import { LinkWithScrollToTop, SVGIcon } from 'gogo-sphere-shared-components';

export class _ForgotPassword extends React.Component {
	static propTypes = {
		sendingEmailDone: PropTypes.bool,
		errorMessage: PropTypes.object,
		submitEmailForPasswordReset: PropTypes.func,
		isSendingEmail: PropTypes.bool,
		resetPasswordStatus: PropTypes.func,
	};

	constructor(props) {
		super(props);

		this.state = {
			email: '',
		};
	}

	componentWillUnmount() {
		this.props.resetPasswordStatus();
	}

	onInputChange = event => {
		const { name } = event.target;
		const { value } = event.target;

		this.setState({ [name]: value });
	};

	getErrorMessage = () => {
		if (this.props.errorMessage && this.props.errorMessage.status === 404) {
			if (this.state.email.endsWith('intelsat.com')) {
				return (
					<div className="LoginBox__error" data-automation="textWrongCredentials">
						<div className="LoginBox__errorSlogan h-errorColor">
							<SVGIcon icon="ExclamationTriangle" wrapperClassName="h-marginR--xxs" size={35} />
							<h1>Warning message</h1>
						</div>
						<p className="h-textCenter" data-automation="textForgotPasswordError">
							Forgot password is not supported for Intelsat users.
						</p>
					</div>
				);
			}
			return (
				<div className="LoginBox__error" data-automation="textWrongCredentials">
					<div className="LoginBox__errorSlogan h-errorColor">
						<SVGIcon icon="ExclamationTriangle" wrapperClassName="h-marginR--xxs" size={35} />
						<h1>Warning message</h1>
					</div>
					<p className="h-textCenter" data-automation="textForgotPasswordError">
						User {this.state.email} &nbsp;does not exist.
					</p>
				</div>
			);
		} else if (this.props.errorMessage) {
			return (
				<div className="LoginBox__error" data-automation="textWrongCredentials">
					<div className="LoginBox__errorSlogan h-errorColor">
						<SVGIcon icon="ExclamationTriangle" wrapperClassName="h-marginR--xxs" size={35} />
						<h1>Warning message</h1>
					</div>
					<p className="h-textCenter" data-automation="textForgotPasswordError">
						There was an error processing your request.
					</p>
				</div>
			);
		}

		return null;
	};

	getSuccessMessage = () => {
		const [email] = [this.state.email];

		if (!this.props.errorMessage && this.props.sendingEmailDone) {
			return (
				<div className="LoginBox__error LoginBox__error--success" data-automation="regionForgotPasswordSuccess">
					<div className="LoginBox__errorSlogan h-successColor">
						<SVGIcon icon="CheckMarkSignIco" wrapperClassName="h-marginR--xxs" size={35} />
						<h1 className="LoginBox__heading">Success!</h1>
					</div>
					<p
						className="h-textCenter"
						data-automation="textForgotPasswordSuccess"
					>{`An email with instructions has been sent to ${email}`}</p>
				</div>
			);
		}

		return null;
	};

	handleSubmit = event => {
		event.preventDefault();
		this.props.submitEmailForPasswordReset(this.state.email);
	};

	_generateProcessingButton = () => {
		let caption = '';
		if (this.props.isSendingEmail) {
			caption = 'Processing...';
		}
		if (caption && !this.props.errorMessage) {
			return (
				<button
					type="submit"
					className="Button Button--action Button--action--alternative h-fullWidth h-flexCenter"
					disabled
					data-automation="actionForgotPasswordSendEmailDisabled"
				>
					<SVGIcon icon="Spinner2" size={25} wrapperClassName="spinAnimation" />
					{caption}
				</button>
			);
		}
		return (
			<button
				type="submit"
				className="Button Button--action Button--action--alternative h-fullWidth"
				data-automation="actionForgotPasswordSendEmail"
			>
				Submit
			</button>
		);
	};

	render() {
		const errorMessage = this.getErrorMessage();
		const successMessage = this.getSuccessMessage();
		let message;

		if (successMessage) {
			message = successMessage;
		} else if (errorMessage) {
			message = !this.props.sendingEmailDone ? errorMessage : false;
		} else {
			message = [
				<h1 className="LoginBox__heading" data-automation="textWelcome" key="textWelcome">
					Password Reset
				</h1>,
				<p className="LoginBox__comment" key="loginComment">
					Enter your email, and we will send you instructions on how to reset your password.
				</p>,
			];
		}

		return (
			<div className="Login" data-automation="regionForgotPasswordPage">
				<LinkWithScrollToTop to="/" data-automation="actionGoToForgotPasswordPage" className="Login__logo" />

				<div className="LoginBox">
					{message}
					<form onSubmit={this.handleSubmit} className="LoginBox__form h-marginT--md">
						{successMessage ? (
							<div />
						) : (
							<div>
								<div className="LoginBox__formElement">
									<input
										id="inputEmail"
										className="InputBox"
										data-automation="filedEmail"
										type="email"
										name="email"
										placeholder="Email"
										value={this.state.email}
										onChange={this.onInputChange}
										autoFocus
										required
									/>
								</div>

								<div className="LoginBox__formElement">{this._generateProcessingButton()}</div>
							</div>
						)}
						<div className="LoginBox__formElement LoginBox__linkHolder">
							<LinkWithScrollToTop
								to="/login"
								className="LoginBox__link"
								data-automation="actionGoToLoginPage"
							>
								Back
							</LinkWithScrollToTop>
						</div>
					</form>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	errorMessage: state.passwordStatus.errorMessage,
	isSendingEmail: state.passwordStatus.isSendingEmail,
	sendingEmailDone: state.passwordStatus.sendingEmailDone,
});

const mapDispatchToProps = dispatch => ({
	submitEmailForPasswordReset: email => dispatch(passwordActionCreators.submitEmailForPasswordReset(email)),
	resetPasswordStatus: () => dispatch(passwordActionCreators.resetPasswordStatus()),
});

const ForgotPassword = connect(
	mapStateToProps,
	mapDispatchToProps
)(_ForgotPassword);

export default class extends React.Component {
	render() {
		return (
			<Provider store={store}>
				<ForgotPassword {...this.props} />
			</Provider>
		);
	}
}
