import { withLoadable } from 'gogo-sphere-shared-components';

export default {
	name: 'Installation Management Portal', // Application name
	shortName: 'Installation Mgmt Portal',
	path: 'imp', // Path that is being used in a router
	redirect: 'imp/myforms', // Used in app switcher and app selector to control on which url will application open
	roles: [], // For which roles app would be open - deprecated soon
	airlines: '*', // For which airlines app would be open
	noAppHeader: true, // Should application have header with default breadcrumbs
	tabNavigationOverride: true, // Tab navigation instead of sidebar,
	exceptionalRestriction: () => window.env !== 'dev' && window.env !== 'stage',
	showPartnerLogo: true, // shows currently selected airline logo
	component: withLoadable(() =>
		import(/*webpackChunkName: "imp"*/
		/*webpackMode: "lazy"*/
		'gogo-sphere-app-imp')
	),
	permission: {
		domain: 'imp',
	},
	pages: [
		// Defines paged for application
		{
			sidebar: true, // Should page be shown in menu
			name: 'My Forms', // Name for the application - used in sidebar
			path: 'myforms',
			component: 'MyForms', // Component which is called for this router
			roles: [], // For which roles page would be open - deprecated soon
			pages: [
				{
					name: 'Record',
					path: 'record/:id',
					component: 'Record', // Component which is called for this route
					roles: [], // For which roles page would be open - deprecated soon
				},
			],
		},
	],
};
