export default {
	name: 'System Health', // Application name
	shortName: 'System Health', // Application name used for app switcher
	class: 'crewOps',
	header: {},
	redirect: '/operations/',
	roles: [],
	airlines: '*', // For which airlines app would be open
	permission: {
		domain: 'crewproxy',
	},
	pages: [],
	externalApp: true,
	openInSameWindow: false,
};
