import React from 'react';
import { LinkWithScrollToTop } from 'gogo-sphere-shared-components';

export default class extends React.Component {
	static displayName = '404';

	render() {
		return (
			<div className="ErrorPage h-textCenter" data-automation="region404Page">
				<br />
				<br />
				<br />
				<br />
				<div data-automation="region404" className="h-centerVerticallyAndHorizontally">
					<div>
						<h1 data-automation="text404" className="xxl">
							404
						</h1>
						<p data-automation="textOops" className="ErrorPage__text h-whiteText">
							Oops! Something went wrong
						</p>
						<p data-automation="textPageNotFound" className="ErrorPage__text h-whiteText">
							Page not found. Return to &nbsp;
							<LinkWithScrollToTop className="ErrorPage__link" data-automation="actionGoToHomePage" to="/">
								Home page
							</LinkWithScrollToTop>
						</p>
					</div>
				</div>
			</div>
		);
	}
}
