import React from 'react';
import { connect, Provider } from 'react-redux';
import PropTypes from 'prop-types';
import { store, passwordActionCreators } from 'gogo-sphere-shared-redux';
import { LinkWithScrollToTop, PasswordStrength, SVGIcon } from 'gogo-sphere-shared-components';
import { validatePassword } from 'gogo-sphere-shared-util';

export class _CreateNewPassword extends React.Component {
	static propTypes = {
		errorMessage: PropTypes.object,
		passwordResetDone: PropTypes.bool,
		isPasswordResetting: PropTypes.bool,
		resetPassword: PropTypes.func,
		resetPasswordStatus: PropTypes.func,
	};

	constructor(props) {
		super(props);

		this.state = {
			error: false,
			isPasswordValid: false,
			passwordStrengthErrors: null,
			password: '',
			confirmPassword: '',
		};

		this.handlePasswordChange = ::this.handlePasswordChange;
		this.handleSubmit = ::this.handleSubmit;
	}

	componentDidMount() {
		this._parseQueryString();
	}

	componentDidUpdate(prevProps) {
		if (this.props.location.search !== prevProps.location.search) {
			this._parseQueryString();
		}

		if (prevProps.errorMessage !== this.props.errorMessage) {
			this.getErrors();
		}

		if (this.props.passwordResetDone && !prevProps.passwordResetDone) {
			this.props.history.push('/login/reset');
		}
	}

	componentWillUnmount() {
		this.props.resetPasswordStatus();
	}

	getErrors = () => {
		const lastError = this.props.errorMessage;

		if (lastError && lastError.errors) {
			//eslint-disable-next-line
			const errorMessages = lastError.errors.reduce((prevVal, elem) => {
				return (
					<p>
						{prevVal || ''} {elem.message || elem} <br />
					</p>
				);
			}, 0);

			this.setState({
				error: errorMessages,
			});
		} else if (lastError && lastError.message) {
			this.setState({
				error: lastError.message,
			});

			return <p>{lastError.message}</p>;
		}
		return null;
	};

	getErrorMessage = () => {
		if (this.state.error) {
			return (
				<div className="LoginBox__error" data-automation="textErrorInProcessingRequest">
					<div className="LoginBox__errorSlogan h-errorColor">
						<SVGIcon icon="ExclamationTriangle" wrapperClassName="h-marginR--xxs" size={35} />
						<h1>Warning message</h1>
					</div>
					<div className="h-textCenter" data-automation="actionPasswordResetError">
						{this.state.error}
					</div>
				</div>
			);
		} else if (!this.state.isPasswordValid && this.state.password.length > 0) {
			return (
				<div className="LoginBox__error" data-automation="textErrorInProcessingRequest">
					<div className="LoginBox__errorSlogan h-errorColor">
						<SVGIcon icon="ExclamationTriangle" wrapperClassName="h-marginR--xxs" size={35} />
						<h1>Password strength</h1>
					</div>
					<div className="h-textCenter" data-automation="actionPasswordResetError">
						<PasswordStrength errors={this.state.passwordStrengthErrors} />
					</div>
				</div>
			);
		}
		return null;
	};

	handlePasswordChange(event) {
		this.setState({
			[event.target.name]: event.target.value,
		});

		if (event.target.name === 'password') {
			const { errors } = validatePassword(event.target.value);
			const { isPasswordValid } = validatePassword(event.target.value);

			this.setState({
				passwordStrengthErrors: isPasswordValid ? {} : errors,
				isPasswordValid,
				error: !isPasswordValid ? false : this.state.error,
			});
		}
	}

	handleSubmit(event) {
		event.preventDefault();

		if (this.state.password === this.state.confirmPassword && this.state.isPasswordValid) {
			this.setState(
				{
					error: false,
				},
				this.props.resetPassword.bind(null, this.params.email, this.params.signature, this.state.password)
			);
		} else if (this.state.password !== this.state.confirmPassword) {
			this.setState({
				error: "Passwords don't match",
			});
		}
	}
	_parseQueryString = () => {
		const params = this.props.location.search.substr(1);

		this.params = {};

		params.split('&').forEach(param => {
			const paramSplit = param.split('=');
			//eslint-disable-next-line
			this.params[paramSplit[0]] = paramSplit[1];
		});
	};

	render() {
		return (
			<div className="Login" data-automation="regionResetPassword">
				<LinkWithScrollToTop to="/" data-automation="actionGoToForgotPasswordPage" className="Login__logo" />

				<div className="LoginBox">
					{this.state.error || (!this.state.isPasswordValid && this.state.password.length > 0) ? (
						this.getErrorMessage()
					) : (
						<div>
							<h1 className="LoginBox__heading" data-automation="textCreateNewPasswordCaption">
								Create new password
							</h1>
							<p className="LoginBox__comment">Please enter new password.</p>
						</div>
					)}

					<form onSubmit={this.handleSubmit} id="changePasswordForm" className="LoginBox__form h-marginT--md">
						<div className="LoginBox__formElement">
							<input
								type="password"
								name="password"
								className="InputBox"
								required
								disabled={this.props.passwordResetDone || this.props.isPasswordResetting}
								data-automation="fieldNewPassword1"
								placeholder="New password"
								onChange={this.handlePasswordChange}
								value={this.state.password}
								autoFocus
							/>
						</div>

						<div className="LoginBox__formElement">
							<input
								type="password"
								name="confirmPassword"
								className="InputBox"
								required
								disabled={this.props.passwordResetDone || this.props.isPasswordResetting}
								data-automation="fieldNewPassword2"
								placeholder="Re-type password"
								onChange={this.handlePasswordChange}
								value={this.state.confirmPassword}
							/>
						</div>

						<div className="LoginBox__formElement">
							<button
								type="submit"
								className="Button Button--action Button--action--alternative h-fullWidth"
								data-automation="fieldResetPasswordButton"
								disabled={!this.state.isPasswordValid || this.props.isPasswordResetting}
							>
								Reset password
							</button>
						</div>

						<div className="LoginBox__formElement LoginBox__linkHolder">
							<LinkWithScrollToTop
								to="/login"
								className="LoginBox__link"
								data-automation="actionGoToLoginPage"
							>
								Back
							</LinkWithScrollToTop>
						</div>
					</form>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	errorMessage: state.passwordStatus.errorMessage,
	isPasswordResetting: state.passwordStatus.isPasswordResetting,
	passwordResetDone: state.passwordStatus.passwordResetDone,
});

const mapDispatchToProps = dispatch => ({
	resetPassword: (email, signature, newPassword) =>
		dispatch(passwordActionCreators.resetPassword(email, signature, newPassword)),
	resetPasswordStatus: () => dispatch(passwordActionCreators.resetPasswordStatus()),
});

const CreateNewPassword = connect(
	mapStateToProps,
	mapDispatchToProps
)(_CreateNewPassword);

export default class extends React.Component {
	render() {
		return (
			<Provider store={store}>
				<CreateNewPassword {...this.props} />
			</Provider>
		);
	}
}
