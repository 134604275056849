import React from 'react';
import { connect, Provider } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { store, authActionCreators, getUserPrefs, baseUrl, MicrosoftOnlineSSOUrl } from 'gogo-sphere-shared-redux';
import { LinkWithScrollToTop, NotificationSystem, SVGIcon } from 'gogo-sphere-shared-components';

import SSOLogin from './SSOLogin';

class _Login extends React.Component {
	static propTypes = {
		loginStatus: PropTypes.string,
		loginErrorMessage: PropTypes.string,
		sendRequestToGetCurrentUserDetails: PropTypes.func,
		getUserPrefs: PropTypes.func,
		clearLoginError: PropTypes.func,
		sendCredentials: PropTypes.func,
		match: PropTypes.object,
		notifications: PropTypes.array,
		forcedFromPage: PropTypes.string,
	};

	state = {
		email: '',
		password: '',
		SSOLoggingIn: false,
	};

	componentDidMount() {
		this.props.clearLoginError();

		window.adobeDTMData = {
			airline: null,
			roles: null,
		};
	}

	componentDidUpdate(prevProps) {
		if (
			this.props.loginStatus !== prevProps.loginStatus &&
			this.props.loginStatus === 'FETCHING_USER_DETAILS' &&
			!this.props.loginErrorMessage
		) {
			this.props.sendRequestToGetCurrentUserDetails();
		}

		if (
			this.props.loginStatus !== prevProps.loginStatus &&
			this.props.loginStatus === 'LOGGED_IN' &&
			!this.props.loginErrorMessage
		) {
			this.props.getUserPrefs();

			this.props.history.push(this.props.forcedFromPage);
		}
	}

	onInputChange = event => {
		const { name } = event.target;
		const { value } = event.target;
		this.setState({ [name]: value });
	};

	handleSubmit = event => {
		event.preventDefault();

		const email = this.state.email.trim();
		const password = this.state.password.trim();

		this.props.sendCredentials(email, password);
	};

	_generateLoginButton = () => {
		let caption = null;

		if (this.props.loginStatus === 'LOGGING_IN') {
			caption = 'Signing in';
		} else if (this.props.loginStatus === 'FETCHING_USER_DETAILS') {
			caption = 'Loading';
		}

		if (caption) {
			return (
				<button
					type="submit"
					className="Button Button--action Button--action--alternative h-fullWidth h-flexCenter"
					disabled
					data-automation="fieldLoginProgressDisabledButton"
				>
					<SVGIcon icon="Spinner2" size={25} wrapperClassName="spinAnimation" />
					{caption}
				</button>
			);
		}
		return (
			<button
				type="submit"
				className="Button Button--action Button--action--alternative h-fullWidth"
				data-automation="fieldLoginButton"
				disabled={this.props.loginStatus === 'LOGGING_IN' || this.state.SSOLoggingIn}
				onClick={this.handleSubmit}
			>
				Sign In
			</button>
		);
	};

	handleSSO = provider => {
		this.setState({ SSOLoggingIn: true });

		if (provider === 'microsoftonline') {
			window.location.href = MicrosoftOnlineSSOUrl;
		} else {
			const callbackUri = `${window.location.origin}/sso/saml`;
			window.location.href = `${baseUrl}/saml/login?IdP=${provider}&callbackUri=${callbackUri}`;
		}
	};

	render() {
		let message;
		if (this.props.loginErrorMessage) {
			message = (
				<div className="LoginBox__error" data-automation="textWrongCredentials" id="textWrongCredentials">
					<div className="LoginBox__errorSlogan h-errorColor">
						<SVGIcon icon="ExclamationTriangle" wrapperClassName="h-marginR--xxs" size={35} />
						<h1>Warning message</h1>
					</div>
					<p className="h-textCenter">{this.props.loginErrorMessage}</p>
				</div>
			);
		} else if (this.props.match && this.props.match.params && this.props.match.params.reset == 'reset') {
			message = (
				<div className="LoginBox__error LoginBox__error--success" data-automation="textPasswordReset">
					<div className="LoginBox__errorSlogan h-successColor">
						<SVGIcon icon="CheckMarkSignIco" wrapperClassName="h-marginR--xxs" size={35} />
						<h1 className="LoginBox__heading">Password reset</h1>
					</div>
					<p className="h-textCenter">
						Your password has been reset. Please try logging in with your new password.
					</p>
				</div>
			);
		} else {
			message = [
				<h1
					className="LoginBox__heading"
					data-automation="textWelcome"
					key="login-welcome-title"
					id="loginWelcomeTitle"
				>
					Welcome
				</h1>,
				<p className="LoginBox__comment" key="login-welcome-text">
					Please sign in with your email address and password
				</p>,
			];
		}

		return (
			<div className="Login" data-automation="regionLoginPage">
				<NotificationSystem notifications={this.props.notifications} />

				<LinkWithScrollToTop to="/" data-automation="actionGoToForgotPasswordPage" className="Login__logo" />

				<div className="LoginBox">
					{message}
					<form className="LoginBox__form h-marginT--md" autoComplete="off">
						<div className="LoginBox__formElement">
							<input
								type="email"
								name="email"
								className="InputBox"
								placeholder="Email"
								required
								data-automation="fieldEmail"
								id="username"
								autoFocus
								disabled={this.props.loginStatus === 'LOGGING_IN'}
								value={this.state.email}
								onChange={this.onInputChange}
							/>
						</div>

						<div className="LoginBox__formElement">
							<input
								type="password"
								name="password"
								className="InputBox"
								placeholder="Password"
								required
								data-automation="fieldPassword"
								id="password"
								disabled={this.props.loginStatus === 'LOGGING_IN'}
								value={this.state.password}
								onChange={this.onInputChange}
							/>
						</div>

						<div className="LoginBox__formElement">{this._generateLoginButton()}</div>

						<div className="LoginBox__formElement LoginBox__linkHolder">
							<LinkWithScrollToTop
								to="/forgotpassword"
								className="LoginBox__link"
								data-automation="actionGoToForgotPasswordPage"
							>
								Forgot Password
							</LinkWithScrollToTop>
						</div>

						<SSOLogin
							disabled={this.props.loginStatus === 'LOGGING_IN'}
							isLoggingIn={this.state.SSOLoggingIn}
							handleSSO={this.handleSSO}
						/>
					</form>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	loginErrorMessage: state.loginErrorMessage,
	loginStatus: state.loginStatus,
	notifications: state.notifications,
	userDetails: state.userDetails,
	forcedFromPage: state.forcedFromPage,
});

const mapDispatchToProps = dispatch => ({
	sendCredentials: (email, password) => dispatch(authActionCreators.sendCredentials(email, password)),
	sendRequestToGetCurrentUserDetails: () => dispatch(authActionCreators.sendRequestToGetCurrentUserDetails()),
	getUserPrefs: () => dispatch(getUserPrefs()),
	clearLoginError: () => dispatch(authActionCreators.clearLoginError()),
});

export const LoginWithRouter = withRouter(_Login);
const Login = connect(
	mapStateToProps,
	mapDispatchToProps
)(LoginWithRouter);

export default class extends React.Component {
	render() {
		return (
			<Provider store={store}>
				<Login {...this.props} />
			</Provider>
		);
	}
}
