import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { hot, setConfig } from 'react-hot-loader';

import { hasAccess } from 'gogo-sphere-shared-util';
import { AppContainer, generatePageRoutesAndNavItems, instantiateNavChildren } from 'gogo-sphere-shared-components';

// eslint-disable-next-line
import staticApps from 'apps';

import HealthCheck from './Components/HealthCheck';
import Login from './Components/Auth/Login';
import Logout from './Components/Auth/Logout';
import ForgotPassword from './Components/Auth/ForgotPassword';
import CreateNewPassword from './Components/Auth/CreateNewPassword';
import AppSelector from './Components/AppSelector';
import Error403 from './Components/ErrorPages/403';
import Error404 from './Components/ErrorPages/404';
import LinkHasExpired from './Components/ErrorPages/LinkHasExpired';
import SSOLanding from './Components/Auth/SSOLanding';

import makeTableauAppsManifests from './Util/makeTableauAppManifest';

const LogoutWithRouter = withRouter(Logout);
const CreateNewPasswordWithRouter = withRouter(CreateNewPassword);
const LinkHasExpiredWithRouter = withRouter(LinkHasExpired);
const Error403WithRouter = withRouter(Error403);
const Error404WithRouter = withRouter(Error404);

setConfig({ logLevel: 'debug'});

class Routes extends React.Component {
	static propTypes = {
		setKickTimer: PropTypes.func,
		tableauApps: PropTypes.array,
		BIATableauAppId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		userPrefs: PropTypes.object,
		loginStatus: PropTypes.string,
		tableauInternalApps: PropTypes.array,
	};
	constructor(props) {
		super(props);

		this.mounted = false;
	}

	componentDidMount() {
		window.addEventListener('languagechange', this.onLanguageChange);
		this.mounted = true;
		this.props.setKickTimer();
	}

	shouldComponentUpdate(nextProps) {
		return nextProps.tableauApps.length > 0 || this.props.loginStatus !== nextProps.loginStatus;
	}

	componentWillUnmount() {
		window.removeEventListener('languagechange', this.onLanguageChange);
		this.mounted = false;
	}

	onLanguageChange = () => {
		if (this.mounted) {
			return this.forceUpdate();
		}
		return null;
	};

	render() {
		let tableauAppsManifests;
		let tableauInternalAppsManifests;
		let apps;

		const tableauApps =
			this.props.tableauApps.length > 0
				? this.props.tableauApps.filter(app => app.id != this.props.BIATableauAppId && app.name !== 'bia')
				: [];

		const tableauInternalApps =
			this.props.tableauInternalApps.length > 0
				? this.props.tableauInternalApps.filter(
						app => app.id != this.props.BIATableauAppId && app.name !== 'bia'
				  )
				: [];

		const staticAppsNormalized = Object.keys(staticApps).map(appKey => staticApps[appKey]);

		if (tableauApps.length > 0 && this.props.BIATableauAppId) {
			tableauAppsManifests = makeTableauAppsManifests(tableauApps);
			apps = [...staticAppsNormalized, ...tableauAppsManifests];
		} else {
			apps = [...staticAppsNormalized];
		}

		if (tableauInternalApps.length > 0) {
			tableauInternalAppsManifests = makeTableauAppsManifests(tableauInternalApps);
			apps.push(...tableauInternalAppsManifests);
		}

		const internalApps = apps.filter(app => !app.externalApp);

		const appRoutes = internalApps.map(app => {
			if (app.component) {
				return (
					<Route
						path={`/${app.path}`}
						name={app.path}
						key={app.path}
						render={() => <app.component userPrefs={this.props.userPrefs} app={app} apps={apps} />}
					/>
				);
			}

			// TBL apps have no app.component
			const { pageRoutes, navItems } = generatePageRoutesAndNavItems(app);

			const navChildren = instantiateNavChildren(app, this.props.userPrefs);
			return (
				<Route path={`/${app.path}`} name={app.name} key={app.path}>
					<AppContainer
						app={app}
						apps={apps}
						hasAccessToPage={hasAccess}
						navItems={navItems}
						allowedAirlines={app.airlines}
						noAppHeader={app.noAppHeader}
						tabNavigationOverride={app.tabNavigationOverride}
						navChildren={navChildren}
						pageRoutes={pageRoutes}
					/>
				</Route>
			);
		});

		return (
			<HashRouter>
				<Switch>
					<Route
						exact
						path="/"
						render={() => (
							<AppContainer apps={apps} noAppHeader>
								<AppSelector apps={apps} />
							</AppContainer>
						)}
					/>
					{/* Health check */}
					<Route path="/health" component={HealthCheck} />
					{/*User login */}
					<Route path="/login/:reset?" component={Login} />
					{/* SSO Landing */}
					<Route path="/sso/:saml?" component={SSOLanding} />
					{/* User logout */}
					<Route path="/logout" component={LogoutWithRouter} />
					{/* User forgot password */}
					<Route path="/forgotpassword" component={ForgotPassword} />
					{/* User new password */}
					<Route path="/newpassword" component={CreateNewPasswordWithRouter} />
					{/* User password link expired */}
					<Route path="/linkexpired" component={LinkHasExpiredWithRouter} />
					{/* Global forbidden redirect */}
					<Route path="/forbidden" component={Error403WithRouter} />

					{appRoutes}

					{/*Find solution for this*/}
					<Route component={Error404WithRouter} />
				</Switch>
			</HashRouter>
		);
	}
}

export default hot(module)(Routes);
