import { withLoadable } from 'gogo-sphere-shared-components';

import { checkTopsViewPermissions } from '../Util/topsPermissions';

export default {
	name: 'Technical Operations', // Application name
	shortName: 'Tech Ops',
	path: 'tops', // Path that is being used in a router
	redirect: 'tops/landing', // Used in app switcher and app selector to control on which url will application open
	roles: [], // For which roles app would be open - deprecated soon
	airlines: '*', // For which airlines app would be open
	noAppHeader: true, // Should application have header with default breadcrumbs
	tabNavigationOverride: false, // Tab navigation instead of sidebar,
	exceptionalRestriction: () => window.env !== 'dev' && window.env !== 'stage' && window.env !== 'prod',
	showPartnerLogo: true, // shows currently selected airline logo
	component: withLoadable(() =>
		import(/*webpackChunkName: "tops"*/
		/*webpackMode: "lazy"*/
		'gogo-sphere-app-tops')
	),
	permission: {
		domain: 'tops',
	},
	pages: [
		// Defines paged for application
		{
			sidebar: false, // Should page be shown in menu
			icon: '', // Icon name from Icons.jsx
			name: '', // Name for the application - used in sidebar
			path: 'landing',
			component: 'LandingRedirect', // Component which is called for this router
			roles: [], // For which roles page would be open - deprecated soon
			permission: {
				domain: 'tops',
			},
		},
		{
			sidebar: true, // Should page be shown in menu
			name: 'Configs Management', // Name for the application - used in sidebar
			path: 'tails',
			icon: 'wrench',
			component: 'Tails', // Component which is called for this router
			roles: [], // For which roles page would be open - deprecated soon
			pages: [
				{
					name: 'Tails Details',
					path: 'details/:id',
					component: 'TailsDetails', // Component which is called for this route
					roles: [], // For which roles page would be open - deprecated soon
				},
			],
			// We need this permission here because we need for Partner user with tops packages and without config page permission to be able to access
			// TOPS, and once he open application he will be redirected to deployment planner page. With previous permission check he will be redirected to forbidden
			// page each time he try to open TOPS, so we don't have a chance to check permissions on page mount and redirect if needed
			permission: {
				domain: 'tops',
				target: 'golden_config_status',
				action: 'read'
			},
		},
		{
			sidebar: false, // Should page be shown in menu
			name: 'Configs Management', // Name for the application - used in sidebar
			path: 'configs',
			icon: 'wrench',
			component: 'Configs', // Component which is called for this router
			roles: [], // For which roles page would be open - deprecated soon
			pages: [
				{
					name: 'Manage Overrides',
					path: 'manage',
					component: 'ManageOverrides', // Component which is called for this route
					roles: [], // For which roles page would be open - deprecated soon
				},
			],
			permission: {
				domain: 'tops',
				target: 'golden_config_status',
				action: 'read'
			},
		},
		// Defines paged for application
		{
			sidebar: true, // Should page be shown in menu
			name: 'Deployment Planner', // Name for the application - used in sidebar
			path: 'planner',
			icon: 'wrench',
			component: 'DeploymentPlanner', // Component which is called for this router
			roles: [], // For which roles page would be open - deprecated soon
			pages: [
				{
					name: 'Deployment Details',
					path: 'details/:id',
					component: 'PackageDetails', // Component which is called for this route
					roles: [], // For which roles page would be open - deprecated soon
					permission: checkTopsViewPermissions,
				},
			],
			permission: checkTopsViewPermissions,
		},
	],
};
