import { userRoles } from 'gogo-sphere-shared-redux';
import { withLoadable } from 'gogo-sphere-shared-components';

export default {
	name: 'Flight Information Gateway', // Application name
	shortName: 'Flight Info Gateway', // Application name used for app switcher
	mobileName: 'FIG',
	path: 'fig', // Path that is being used in a router
	redirect: 'fig/flights', // Used in app switcher and app selector to control on which url will application open
	roles: [userRoles.GOGO_SUPER_ADMIN, userRoles.GOGO_ADMIN, userRoles.GOGO_USER], // For which roles app would be open - deprecated soon
	airlines: '*', // For which airlines app would be open
	tabNavigationOverride: true, // Tab navigation instead of sidebar
	exceptionalRestriction: false, // in case this is true, it wont appear on production
	showPartnerLogo: false, // If true, airline logo wont appear in header
	permission: {
		// Permissions for application based on role
		domain: 'fig',
		target: null,
		action: 'read',
	},
	component: withLoadable(() =>
		import(/*webpackChunkName: "fig"*/
		/*webpackMode: "lazy"*/
		'gogo-sphere-app-fig')
	),
	pages: [
		// Defines paged for application
		{
			sidebar: true, // Should page be shown in menu
			name: 'Flights', // Name for the application - used in sidebar
			icon: 'fflights', // Icon name from Icons.jsx
			path: 'flights', // Path added to router to main application path
			component: 'Flights', // Component which is called for this router
			roles: [userRoles.GOGO_SUPER_ADMIN, userRoles.GOGO_ADMIN, userRoles.GOGO_USER], // For which roles page would be open - deprecated soon
			pages: [
				// Defined subpages for pages
				{
					sidebar: false,
					name: 'Flight Details',
					icon: '', // Icon name from Icons.jsx
					path: 'flightdetails',
					component: 'FlightDetails',
					roles: [userRoles.GOGO_SUPER_ADMIN, userRoles.GOGO_ADMIN, userRoles.GOGO_USER],
				},
				{
					sidebar: false,
					name: 'Flight Events',
					icon: '', // Icon name from Icons.jsx
					path: 'flightevents',
					component: 'FlightEvents',
					roles: [userRoles.GOGO_SUPER_ADMIN, userRoles.GOGO_ADMIN, userRoles.GOGO_USER],
				},
			],
		},
		{
			sidebar: false,
			name: 'Connectivities',
			icon: '', // Icon name from Icons.jsx
			path: 'connectivities',
			component: 'Connectivities',
			roles: [userRoles.GOGO_SUPER_ADMIN, userRoles.GOGO_ADMIN, userRoles.GOGO_USER],
		},
		{
			sidebar: true,
			name: 'Aircraft',
			icon: 'faircraft', // Icon name from Icons.jsx
			path: 'aircrafts',
			component: 'Aircrafts',
			roles: [userRoles.GOGO_SUPER_ADMIN, userRoles.GOGO_ADMIN, userRoles.GOGO_USER],
			pages: [
				{
					sidebar: false,
					name: 'Aircraft Details',
					icon: '', // Icon name from Icons.jsx
					path: 'aircraftdetails',
					component: 'AircraftDetails',
					roles: [userRoles.GOGO_SUPER_ADMIN, userRoles.GOGO_ADMIN, userRoles.GOGO_USER],
				},
			],
		},
		{
			sidebar: true,
			name: 'Airports',
			icon: 'fairports', // Icon name from Icons.jsx
			path: 'airports',
			component: 'Airports',
			roles: [userRoles.GOGO_SUPER_ADMIN, userRoles.GOGO_ADMIN, userRoles.GOGO_USER],
			pages: [
				{
					sidebar: false,
					name: 'Airport Details',
					icon: '', // Icon name from Icons.jsx
					path: 'airportdetails',
					component: 'AirportDetails',
					roles: [userRoles.GOGO_SUPER_ADMIN, userRoles.GOGO_ADMIN, userRoles.GOGO_USER],
				},
			],
		},
		{
			sidebar: true,
			name: 'Airlines',
			icon: 'fairlines', // Icon name from Icons.jsx
			path: 'airlines',
			component: 'Airlines',
			roles: [userRoles.GOGO_SUPER_ADMIN, userRoles.GOGO_ADMIN, userRoles.GOGO_USER],
			pages: [
				{
					sidebar: false,
					name: 'Airline Details',
					icon: '', // Icon name from Icons.jsx
					path: 'airlinedetails',
					component: 'AirlineDetails',
					roles: [userRoles.GOGO_SUPER_ADMIN, userRoles.GOGO_ADMIN, userRoles.GOGO_USER],
				},
			],
		},
	],
};
