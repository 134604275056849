import { userRoles } from 'gogo-sphere-shared-redux';
import { withLoadable } from 'gogo-sphere-shared-components';

export default {
	name: 'Business Intelligence and Analytics', // Application name
	shortName: 'Biz Intelligence and Analytics', // Application name used for app switcher
	mobileName: 'BIA', // Application name used for breadcrumbs on small screens
	path: 'bia', // Path that is being used in a router
	redirect: 'bia', // Used in app switcher and app selector to control on which url will application open
	roles: [
		userRoles.GOGO_SUPER_ADMIN,
		userRoles.GOGO_ADMIN,
		userRoles.GOGO_USER,
		userRoles.PARTNER_ADMIN,
		userRoles.USER,
	], // For which roles app would be open - deprecated soon
	airlines: '*', // For which airlines app would be open
	showPartnerLogo: true, // If true, airline logo will appear in header
	extraPermissions: [
		'executive_summary',
		'passenger_activity',
		'aircraft',
		'airline_maintenance',
		'performance',
		'tableau',
	], // Permissions for application based on user
	rememberLastVisitedPage: true,
	component: withLoadable(() =>
		import(/*webpackChunkName: "bia"*/
		/*webpackMode: "lazy"*/
		'gogo-sphere-app-bia')
	),
	pages: [
		// Defines paged for application
		{
			sidebar: true, // Should page be shown in menu
			name: 'Executive summary', // Name for the application - used in sidebar
			icon: 'execsummary', // Icon name from Icons.jsx
			path: 'executivesummary', // Path added to router to main application path
			component: 'ExecutiveSummary', // Component which is called for this router
			roles: [userRoles.GOGO_SUPER_ADMIN, userRoles.GOGO_ADMIN, userRoles.GOGO_USER, userRoles.PARTNER_ADMIN], // For which roles page would be open - deprecated soon
			extraPermission: 'executive_summary', // Permissions for page based on user
			permission: {
				// Permissions for page based on role
				domain: 'bia',
				target: 'executive_summary',
				action: 'read',
			},
		},
		{
			sidebar: true,
			name: 'Passenger activity',
			icon: 'pas', // Icon name from Icons.jsx
			path: 'passengeractivity',
			component: 'PassengerActivity',
			roles: [userRoles.GOGO_SUPER_ADMIN, userRoles.GOGO_ADMIN, userRoles.GOGO_USER, userRoles.PARTNER_ADMIN],
			extraPermission: 'passenger_activity',
			permission: {
				domain: 'bia',
				target: 'passenger_activity',
				action: 'read',
			},
		},
		{
			sidebar: true,
			name: 'Airline maintenance',
			icon: 'wrench', // Icon name from Icons.jsx
			path: 'airlinemaintenance',
			component: 'AirlineMaintenance',
			roles: [userRoles.GOGO_SUPER_ADMIN, userRoles.GOGO_ADMIN, userRoles.GOGO_USER, userRoles.PARTNER_ADMIN],
			extraPermission: 'airline_maintenance',
			permission: {
				domain: 'bia',
				target: 'airline_maintenance',
				action: 'read',
			},
		},
		{
			sidebar: true,
			name: 'Aircraft lookup',
			icon: 'aircraft', // Icon name from Icons.jsx
			path: 'aircraft',
			component: 'Aircraft',
			roles: [userRoles.GOGO_SUPER_ADMIN, userRoles.GOGO_ADMIN, userRoles.GOGO_USER, userRoles.PARTNER_ADMIN],
			extraPermission: 'aircraft',
			permission: {
				domain: 'bia',
				target: 'aircraft',
				action: 'read',
			},
		},
		{
			name: 'Aircraft lookup',
			path: 'aircraft/:tail',
			component: 'Aircraft',
			roles: [userRoles.GOGO_SUPER_ADMIN, userRoles.GOGO_ADMIN, userRoles.GOGO_USER, userRoles.PARTNER_ADMIN],
			extraPermission: 'aircraft',
			permission: {
				domain: 'bia',
				target: 'aircraft',
				action: 'read',
			},
		},
		{
			name: 'Aircraft lookup',
			path: 'aircraft/:airline/:tail',
			component: 'Aircraft',
			roles: [userRoles.GOGO_SUPER_ADMIN, userRoles.GOGO_ADMIN, userRoles.GOGO_USER, userRoles.PARTNER_ADMIN],
			extraPermission: 'aircraft',
			permission: {
				domain: 'bia',
				target: 'aircraft',
				action: 'read',
			},
		},
		{
			sidebar: true,
			name: 'Performance',
			icon: 'performance', // Icon name from Icons.jsx
			path: 'performance',
			component: 'Performance',
			roles: [userRoles.GOGO_SUPER_ADMIN, userRoles.GOGO_ADMIN, userRoles.GOGO_USER, userRoles.PARTNER_ADMIN],
			extraPermission: 'performance',
			permission: {
				domain: 'bia',
				target: 'performance',
				action: 'read',
			},
		},
		{
			sidebar: true,
			name: 'Custom reports',
			icon: 'glossary', // Icon name from Icons.jsx
			path: 'customreports',
			component: 'TableauApp',
			roles: [userRoles.GOGO_SUPER_ADMIN, userRoles.GOGO_ADMIN, userRoles.GOGO_USER, userRoles.PARTNER_ADMIN],
			extraPermission: 'tableau',
			permission: {
				domain: 'tableau',
				target: '*',
				action: 'read',
			},
		},
		{
			name: 'Report',
			path: 'customreports/report/:id',
			component: 'TableauReport',
			roles: [userRoles.GOGO_SUPER_ADMIN, userRoles.GOGO_ADMIN, userRoles.GOGO_USER, userRoles.PARTNER_ADMIN],
			extraPermission: 'tableau',
			permission: {
				domain: 'tableau',
				target: '*',
				action: 'read',
			},
		},
		{
			sidebar: true,
			name: 'Manage custom reports',
			icon: 'glossary', // Icon name from Icons.jsx
			path: 'managecustomreports',
			component: 'ManageTableauReports',
			roles: [userRoles.GOGO_SUPER_ADMIN],
			permission: {
				domain: 'bia',
				target: 'manage_tableau',
				action: 'read',
			},
		},
		// {
		// 	sidebar: true,
		// 	name: 'Glossary',
		// 	icon: 'glossary',
		// 	path: 'glossary',
		// 	component: 'Glossary',
		// 	roles: [
		// 		userRoles.GOGO_SUPER_ADMIN,
		// 		userRoles.GOGO_ADMIN,
		// 		userRoles.GOGO_USER,
		// 		userRoles.PARTNER_ADMIN,
		// 		userRoles.USER,
		// 	],
		// 	permission: {
		// 		domain: 'bia',
		// 	},
		// },
		// {
		// 	sidebar: true,
		// 	name: 'FAQ',
		// 	icon: 'faq', // Icon name from Icons.jsx
		// 	path: 'faqs',
		// 	component: 'Faq',
		// 	roles: [
		// 		userRoles.GOGO_SUPER_ADMIN,
		// 		userRoles.GOGO_ADMIN,
		// 		userRoles.GOGO_USER,
		// 		userRoles.PARTNER_ADMIN,
		// 		userRoles.USER,
		// 	],
		// 	permission: {
		// 		domain: 'bia',
		// 	},
		// },
		{
			sidebar: true,
			name: 'Terms of use',
			icon: 'glossary', // Icon name from Icons.jsx
			path: 'terms_of_use',
			component: 'TermsOfUse',
			roles: [
				userRoles.GOGO_SUPER_ADMIN,
				userRoles.GOGO_ADMIN,
				userRoles.GOGO_USER,
				userRoles.PARTNER_ADMIN,
				userRoles.USER,
			],
			permission: {
				domain: 'bia',
			},
		},
	],
};
