import React from 'react';
import { Redirect } from 'react-router';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { AppHeader, AppIcons } from 'gogo-sphere-shared-components';
import { getAllowedApps } from 'gogo-sphere-shared-redux';

class AppSelector extends React.Component {
	static propTypes = {
		user: PropTypes.object,
		apps: PropTypes.array,
		partner: PropTypes.string,
	};

	componentDidMount() {
		document.querySelector('main').classList.add('landingPage');
	}

	componentWillUnmount() {
		document.querySelector('main').classList.remove('landingPage');
	}

	render() {
		//getAllowedApps is memoized, no need to optimize it out of render
		const apps = getAllowedApps(this.props.user, this.props.apps, this.props.partner);

		if (apps.length == 1 && !apps[0].externalApp) {
			return <Redirect to={apps[0].path} />;
		}
		return (
			<div data-automation="regionPageInner">
				<AppHeader noBreadcrumbsText="Available apps" />

				<AppIcons apps={apps} generate="landing" />
			</div>
		);
	}
}

export default withRouter(AppSelector);
