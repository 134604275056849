import { store as sharedStore } from 'gogo-sphere-shared-redux';
import { success, error } from 'react-notification-system-redux';

const notificationWatcher = () => {
	const action = sharedStore.getState().lastAction || {};
	if (action.type === 'PASSWORD_CHANGED') {
		return sharedStore.dispatch(
			success({
				message: 'Password successfully changed.',
			})
		);
	} else if (action.type === 'EXPORT_TABLE_FAILURE') {
		return sharedStore.dispatch(
			error({
				message: 'Table export failed.',
			})
		);
	}
	return null;
};

sharedStore.subscribe(notificationWatcher);
