import React from 'react';
import { connect, Provider } from 'react-redux';
import PropTypes from 'prop-types';
import { store, tableauReportsActionCreators } from 'gogo-sphere-shared-redux';
import { Report } from 'gogo-sphere-shared-components';

class _TableauReport extends React.Component {
	static propTypes = {
		airline: PropTypes.string,
		report: PropTypes.object,
		getReportToken: PropTypes.func,
	};
	render() {
		return (
			<Report
				airline={this.props.airline}
				report={this.props.report}
				getReportToken={this.props.getReportToken}
			/>
		);
	}
}

const mapStateToProps = state => ({
	report: state.tableauReports.data._embedded ? state.tableauReports.data._embedded.reports[0] : {},
});

const mapDispatchToProps = dispatch => ({
	getReportToken: airline => dispatch(tableauReportsActionCreators.getReportToken(airline)),
});

const TableauReport = connect(
	mapStateToProps,
	mapDispatchToProps
)(_TableauReport);

export default class extends React.Component {
	render() {
		return (
			<Provider store={store}>
				<TableauReport {...this.props} />
			</Provider>
		);
	}
}
