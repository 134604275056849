import { withLoadable } from 'gogo-sphere-shared-components';

export default {
	name: 'Test Lab Manager', // Application name
	path: 'lab', // Path that is being used in a router
	redirect: 'lab/flight/live', // Used in app switcher and app selector to control on which url will application open
	roles: [], // For which roles app would be open - deprecated soon
	tabNavigationOverride: true, // Tab navigation instead of sidebar
	airlines: ['DAL'], // For which airlines app would be open
	showPartnerLogo: true, // If true, airline logo will appear in header
	extraPermissions: ['testlab'], // Permissions for application based on user
	permission: {
		// Permissions for application based on role
		domain: 'testlab',
	},
	component: withLoadable(() =>
		import(/*webpackChunkName: "lab"*/
		/*webpackMode: "lazy"*/
		'gogo-sphere-app-lab')
	),
	pages: [
		// Defines paged for application
		{
			sidebar: true, // Should page be shown in menu
			name: 'Live Flight', // Name for the application - used in sidebar
			icon: 'aircraft', // Icon name from Icons.jsx
			path: 'flight/live', // Path added to router to main application path
			component: 'LiveFlight', // Component which is called for this router
			roles: [], // For which roles page would be open - deprecated soon
			extraPermission: 'testlab', // Permissions for page based on user
		},
		{
			name: 'Test Flight',
			path: 'flight/test',
			component: 'TestFlight',
			roles: [],
			extraPermission: 'testlab',
		},
		{
			sidebar: true,
			name: 'Status',
			label: 'Status',
			path: 'flight/status',
			icon: 'glossary', // Icon name from Icons.jsx
			component: 'LabStatus',
			roles: [],
			extraPermission: 'testlab',
		},
	],
};
